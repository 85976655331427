import React, {useState} from "react";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import ParticlesComponent from "./Particles";
import { projects } from "../data/data";
import "../styles/project.css"

function Projects({isDarkMode}){
    // State to manage filtered projects
    const [filteredProjects, setFilteredProjects] = useState(projects); // Initialize with all projects.

    // Get unique categories from the projects data.
    const categories = ["All", ...new Set(projects.map(project => project.category))];

    // Function to handle filtering
    const filterProjects = (category) => {
        if (category === "All") {
            setFilteredProjects(projects);
        } else {
            const filtered = projects.filter(project => project.category === category); //Extract projects with the given category.
            setFilteredProjects(filtered);
        }
    };

    return (
        <div className="projects-section">
            {/* Particles Background */}
            <ParticlesComponent isDarkMode={isDarkMode} id="tsparticles" />
            <h4 className={`projects-title ${isDarkMode ? 'dark' : 'light'}`}>
                A Glimpse of my projects. Find all of my projects on <a href="https://github.com/anamolkhadka"target="_blank" rel="noopener noreferrer">Github</a>
            </h4>
             {/* Filter Buttons */}
             <div className="filter-buttons">
                <ButtonGroup aria-label="Basic example">
                    {categories.map((category, index) => (
                        <Button 
                            key={index}
                            className={`filter-button ${isDarkMode ? 'dark' : 'light'}`}
                            onClick={() => filterProjects(category)}
                            variant="secondary"
                        >
                        {category}
                        </Button>
                        
                    ))}
                </ButtonGroup>
            </div>
             {/* Project Cards */}
            <div className="project-container">
                {filteredProjects.map((project, index) => (
                    <Card className={`project-card ${isDarkMode ? 'dark' : 'light'}`} key={index}>
                        <Card.Img variant="top" src={project.imageSrc} />
                        <Card.Body>
                            <Card.Title className={`project-title ${isDarkMode ? 'dark' : 'light'}`}>{project.title}</Card.Title>
                            <p className={`project-card-text ${isDarkMode ? 'dark' : 'light'}`}>{project.techStack}</p>
                            <Card.Text className={`project-description ${isDarkMode ? 'dark' : 'light'}`}>{project.description}</Card.Text>
                            <Button href={project.link} target="_blank" rel="noopener noreferrer" variant="outline-primary">
                                More information
                            </Button>
                        </Card.Body>
                    </Card>
                ))}
            </div>
        </div>
    );
}
export default Projects;
