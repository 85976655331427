// About Information
export const about = {
    info: `MS in Computer Science | Software Developer | Graduate Research Assistant | Ex-Accountant | Seeking SWE Internships/Full-time | University of Texas at Arlington`,
    imageSrc: "/images/profile.jpeg"
};

// Interest Information
export const interest = {
    interest: `Software engineering | Full-Stack Software development | Backend Software development | Enterprise business applications | Artificial Intelligence`
};

// Education Information
export const education = [
    {
        school: "The University of Texas at Arlington",
        degree: "Master of Science in Computer Science | Specializations: Software Engineering and Intelligent Systems",
        duration: "Jan 2024 - Present"
    },
    {
        school: "The University of Texas at Arlington",
        degree: "Honors Bachelor of Science, Accounting and Computer Science",
        duration: "August 2019 - December 2023"
    }
];

// Experience Information
export const experience = [
    {
        company: "UT Arlington Libraries",
        role: "Graduate Research Assistant - Software Developer",
        duration: "July 2024 - Present"
    },
    {
        company: "Howard, LLP (Merged to Bonadio Group)",
        role: "Audit Associate",
        duration: "Jan 2023 - Aug 2023"
    },
    {
        company: "CLA (Clifton Larson Allen)",
        role: "Tax Accountant",
        duration: "Jan 2022 - Apr 2022"
    },
    {
        company: "May Mobility",
        role: "Accountant",
        duration: "May 2021 - Dec 2021"
    },
];

// Leadership and College Organizations
export const leadership = [
    {
        organization: "Open Education Student Advocacy Group (OESAG)",
        role: "Founder and President",
        duration: "July 2024 - Present"
    },
    {
        organization: "Beta Gamma Sigma",
        role: "Member",
        duration: "Feb 2022 - Present"
    },
    {
        organization: "Honors College",
        role: "Member",
        duration: "August 2019 - Dec 2023"
    },
    {
        organization: "Accounting Society",
        role: "Vice President | Member",
        duration: "Jan 2020 - Dec 2021"
    },
]

export const technicalSkills = [
    {
        title: "Programming Languages",
        description: "JavaScript, Java, C, Python, Kotlin, SQL, TypeScript."
    },
    {
        title: "Frameworks and Libraries",
        description: "React JS, Bootstrap, jQuery, Node.js, Express.js, Axios, Spring Boot, Spring MVC, JUnit, tkinter, numpy, scipy."
    },
    {
        title: "Database",
        description: "PostgreSQL, SQLite, MySQL, NoSQL, Firebase."
    },
    {
        title: "Methodologies",
        description: "Agile, Waterfall, Spiral Process, Unified Process."
    },
    {
        title: "Software Design Patterns",
        description: "Visitor, Creator, Singleton, State, Expert, Composite, GRASP, Other GoF patterns."
    },
    {
        title: "Web Technologies",
        description: "HTML 5, EJS, CSS, XML, JSON, REST, Github Pages, WordPress, Wix, Canva."
    },
    {
        title: "Tools",
        description: "Git, GitHub, npm, Postman, VS Code, Wireshark, Anaconda."
    },

]

export const projects = [
    {
        title: "StockSync: Real-Time Inventory Management System",
        techStack: "React, Node.js, PostgreSQL, Express.js, JavaScript",
        imageSrc: "/images/inventory.png",
        category:"Full-stack application",
        description: `StockSync is a comprehensive full-stack project designed to manage perpetual inventory systems in accounting. 
        The application provides real-time inventory valuation using the average inventory pricing method, crucial for calculating the cost of sales 
        for financial reporting. Users can manage their profiles, customer and supplier information, inventory items, transactions, and more.`,
        link: "https://github.com/anamolkhadka/StockSync_Public"
    },
    {
        title: "Student Information Exchange (Android application)",
        imageSrc: "/images/SIE.png",
        techStack: "Kotlin, XML, Firebase, NoSQL, Android Studio",
        category:"Mobile application",
        description: `Student Information Exchange is an Android application that helps the university students to exchange information. 
        Students can use this app to form an organization/clubs , buy/sell/trade second hand items , request for tutoring services , 
        direct message to the individuals or in the Group , search for any Upcoming events , and much more.`,
        link: "https://github.com/anamolkhadka/Student_Information_Exchange_project"
    },
    {
        title: "Car Rental Service",
        imageSrc: "/images/carRental.png",
        techStack: "Python, tkinter, SQL, SQLite",
        category:"Full-stack application",
        description: `This is a car rental database management application created for businesses providing car rental services. 
        This application is created using Python for front-end/back-end and SQLite for database management.`,
        link: "https://github.com/anamolkhadka/carRental"
    },
    {
        title: "My Journal",
        imageSrc: "/images/myjournal.png",
        techStack: "React, JavaScript, Firebase, NodeJS, HTML, CSS, Boostrap 5",
        category:"Full-stack application",
        description: `My Journal is a react based web application that is integrated with the real time database management system with firestone. 
        This web app provides a platform to any users to create an online Journal for record keeping and expressing their daily activities, events and emotions they want to convey.`,
        link: "https://github.com/anamolkhadka/myjournal"
    },
    {
        title: "Red-Blue Nim Game (Min-Max Problem)",
        imageSrc: "/images/minmax.png",
        techStack: "Python, Min-Max Algorithm, Alpha-beta pruning, Depth-Limited Search",
        category:"Aritificial Intelligence / Machine Learning",
        description: `This project is an implementation of the Red-Blue Nim game using the Min-Max algorithm with Alpha-Beta Pruning, designed as part of an AI coursework assignment. 
        The game features two versions: standard and misère, with the goal of optimizing the computer's strategy to either win or minimize losses based on the rules of the version being played.`,
        link: "https://github.com/anamolkhadka/CSE5360_red_blue_nim"
    },
    {
        title: "Bayesian Network Classifier",
        imageSrc: "/images/bayesianNetwork.png",
        techStack: "Python, Bayesian Network, Bayes Classifier, Conditional Probabilities, Joint Probability Distribution, Inference by Enumeration.",
        category:"Aritificial Intelligence / Machine Learning",
        description: `This project implements a Bayesian Network Classifier that learns conditional probability tables (CPTs) from training data and performs inference using enumeration.`,
        link: "https://github.com/anamolkhadka/CSE5360_bayesian_classifier"
    },
    {
        title: "Stock Analyzer",
        imageSrc: "/images/stockAnalyzer.png",
        techStack: "React.js, Axios, React-Bootstrap, Chart.js, Firebase, Vercel, Polygon.io Stock API",
        category:"Full-stack application",
        description: `The Stock Analyzer Web App is a simple, intuitive tool designed to help users track and analyze stocks using data from the Polygon.io API. 
        Users can search for stocks, add them to a personalized list, and view detailed stock information, including charts, financial analysis and technical analysis indicators.`,
        link: "https://hack-uta-6.vercel.app/"
    },
    {
        title: "Simon Game",
        imageSrc: "/images/SimonGame.png",
        techStack: "HTML, CSS, JavaScript, jQuery",
        category:"Front-end application",
        description: `This is a web-based implementation of the classic Simon game. The game tests your memory and pattern recognition skills as you attempt to replicate increasingly 
        complex sequences of colored buttons and tones.`,
        link: "https://anamolkhadka.github.io/simon_game/"
    },
    {
        title: "Drum Kit",
        imageSrc: "/images/DrumKit.png",
        techStack: "HTML, CSS, JavaScript, jQuery",
        category:"Front-end application",
        description: `This is a Drum Kit website that allows user to play drum sounds using the keys or button clicks.`,
        link: "https://anamolkhadka.github.io/DrumKit/"
    },
    {
        title: "ToDoList",
        imageSrc: "/images/ToDoList.png",
        techStack: "EJS, CSS, Bootstrap, JavaScript, Express, Node, npm, Axios, SQL, Postgres",
        category:"Full-stack application",
        description: `This is a simple CRUD based To Do List web application that allows users to create, read, update, and delete items from the list.`,
        link: "https://github.com/anamolkhadka/ToDoList"
    },
    {
        title: "Tip App",
        imageSrc: "/images/Tip_App.png",
        techStack: "Kotlin, XML, Android Studio",
        category:"Mobile application",
        description: `This is a simple Android App that can be handy whenever people visit restaurants, cafe, salons or other service oriented businesses 
        and want to tip people for their service. It is simple and easy to use that does math for you and also helps to know the tip and the total amount after rounding up.`,
        link: "https://github.com/anamolkhadka/Tip_App"
    },
]